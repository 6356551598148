<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改分销商用户信息' : '新建分销商用户信息'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 4 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 20 }, sm: { span: 24 } }"
    >
      <a-row>
        <a-col :span="24">
          <a-form-item label="分销商姓名:" name="realName">
            <a-input
              v-model:value="form.realName"
              placeholder="请输入分销商姓名"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="手机号:" name="moible">
            <a-input
              v-model:value="form.moible"
              placeholder="请输入手机号"
              allow-clear
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- 门店选址弹出层 -->
    <a-modal
      :width="950"
      :visible="showPostion"
      title="地图选址"
      :body-style="{ paddingBottom: '8px' }"
      @cancel="cancelPosition"
      @ok="setPosition"
    >
      <Map ref="map" v-model:position="position" id="pointAddress" />
    </a-modal>
  </a-modal>
</template>

<script>
import * as dealerOrderApi from '@/api/dealer/order.js'

function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'shopEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    Map
  },
  data() {
    return {
      form: Object.assign({}, this.data),
      logoPicList: [],
      // 编辑弹窗表单验证规则
      rules: {
        // groupId: [
        //   {
        //     required: true,
        //     message: '请输入站点组名称',
        //     type: 'string',
        //     trigger: 'blur'
        //   },
        //   {
        //     required: true,
        //     message: '请输入所属运营商',
        //     type: 'string',
        //     trigger: 'blur'
        //   }
        // ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      if(this.isUpdate){
        this.form.corpId = this.getCorpId()
      }
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          dealerOrderApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>
