<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="640"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 6 }, xs: { span: 8 } }"
      :wrapper-col="{ sm: { span: 18 }, xs: { span: 16 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 租赁点名称 -->
          <a-form-item label="用户ID:">
            <div class="ud-text-secondary">
              {{ data.userId }}
            </div>
          </a-form-item>
          <!-- 租赁点类型 -->
          <a-form-item label="姓名:">
            <div class="ud-text-secondary">
              {{ data.realName }}
            </div>
          </a-form-item>
          <a-form-item label="手机号:">
            <div class="ud-text-secondary">
              {{ data.moible }}
            </div>
          </a-form-item>
          <a-form-item label="累计佣金:">
            <div class="ud-text-secondary">
              {{ data.totalMoney }}
            </div>
          </a-form-item>
          <!-- 管理人 -->
          <a-form-item label="可提现佣金:">
            <div class="ud-text-secondary">
              {{ data.money }}
            </div>
          </a-form-item>
          <a-form-item label="已冻结佣金:">
            <div class="ud-text-secondary">
              {{ data.freezeMoney }}
            </div>
          </a-form-item>
          <a-form-item label="推荐人用户ID:">
            <div class="ud-text-secondary">
              {{ data.refereeId }}
            </div>
          </a-form-item>
          <a-form-item label="一级成员数量:">
            <div class="ud-text-secondary">
              {{ data.firstNum }}
            </div>
          </a-form-item>
          <a-form-item label="二级成员数量:">
            <div class="ud-text-secondary">
              {{ data.secondNum }}
            </div>
          </a-form-item>
          <a-form-item label="三级成员数量:">
            <div class="ud-text-secondary">
              {{ data.thirdNum }}
            </div>
          </a-form-item>
          <a-form-item label="成为时间:">
            <div class="ud-text-secondary">
              {{ data.createTime }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'OperRecordDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {}
  },
  watch: {
    data() {}
  },
  methods: {
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
.photo {
  width: 200px;
  height: 100px;
  margin: 20px 0;
}
</style>
